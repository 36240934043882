<template>
  <div
    style="width: 850px !important; margin: 0 auto"
    id="payslip"
    class="bg-gray-100 border-2 border-gray p-2 rounded-2xl"
  >
    <div class="flex items-center justify-between text-sm">
      <h2 class="font-semibold">
        ABIA STATE CIVIL SERVICE PAYROLL PAYSLIP
        <span class="font-extrabold ml-2">
          {{
            Number.isInteger(Number(payslipInfo.month))
              ? months[Number(payslipInfo.month) - 1]
              : payslipInfo.month
          }}
          {{ payslipInfo.year }}
        </span>
      </h2>
      <p>
        <span class="text-gray-600 mr-2 font-light">REF CODE</span>
        <strong>{{ payslipInfo.ref ?? "NaN" }}</strong>
      </p>
    </div>
    <hr class="border-[0.5px] border-gray-400 my-2" />
    <div class="flex items-center flex-row gap-2 h-[96px]">
      <div class="h-full min-w-[10%] p-1">
        <img
          style="width: 100%; height: 85px"
          :src="userData.photograph"
          alt=""
        />
      </div>
      <div class="min-w-[220px] space-y-0.5">
        <div
          style="width: 220px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          name
        </div>
        <div
          style="width: 220px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          GRADE LEVEL/STEP
        </div>
        <div
          style="width: 220px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          DESIGNATION
        </div>
        <div
          style="width: 220px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          MDA
        </div>
        <!-- <div
          style="width: 220px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          REMAINING DAYS IN SERVICE
        </div> -->
      </div>
      <div class="min-w-[420px] space-y-0.5">
        <div
          style="width: 420px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          {{
            payslipInfo.name === " " || !payslipInfo.name
              ? "NaN"
              : payslipInfo.name
          }}
        </div>
        <div
          style="width: 420px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          {{
            payslipInfo.grade === " " || !payslipInfo.grade
              ? "NaN"
              : payslipInfo.grade
          }}
        </div>
        <div
          style="width: 420px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          {{
            payslipInfo.designation === " " || !payslipInfo.designation
              ? "NaN"
              : payslipInfo.designation
          }}
        </div>
        <div
          style="width: 420px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          {{
            payslipInfo.mda === " " || !payslipInfo.mda
              ? "NaN"
              : payslipInfo.mda
          }}
        </div>

        <!-- <div
          style="width: 420px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          NaN
        </div> -->
      </div>
      <div class="bg-[#DEFFDE] w-[10%] h-full p-1">
        <img style="width: 100%; height: 85px" :src="payslipInfo.qr" alt="" />
      </div>
    </div>

    <div class="flex items-center flex-row h-[92px] gap-2 mt-2">
      <div class="grow space-y-0.5">
        <div
          style="width: 200px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          DEPARTMENT
        </div>
        <div
          style="width: 200px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          SALARY STRUCTURE
        </div>
        <div
          style="width: 200px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          VERIFICATION NUMBER
        </div>
      </div>
      <div class="grow space-y-0.5">
        <div
          style="width: 200px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          {{
            payslipInfo.department === " " || !payslipInfo.department
              ? "NaN"
              : payslipInfo.department
          }}
        </div>
        <div
          style="width: 200px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          {{
            payslipInfo.salaryStructure === " " || !payslipInfo.salaryStructure
              ? "NaN"
              : payslipInfo.salaryStructure
          }}
        </div>
        <div
          style="width: 200px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          {{
            payslipInfo.verificationCode === " " ||
            !payslipInfo.verificationCode
              ? "NaN"
              : payslipInfo.verificationCode
          }}
        </div>
      </div>
      <div class="grow space-y-0.5">
        <div
          style="width: 200px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          Date of Retirement
        </div>
        <div
          style="width: 200px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          BANK
        </div>

        <div
          style="width: 200px"
          class="rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold"
        >
          ACCOUNT NUMBER
        </div>
      </div>
      <div class="grow space-y-0.5">
        <div
          style="width: 200px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          {{ new Date(payslipInfo.dateOfRetirement).toDateString() ?? "NaN" }}
        </div>
        <div
          style="width: 200px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          {{
            payslipInfo.bankName === " " || !payslipInfo.bankName
              ? "NaN"
              : payslipInfo.bankName
          }}
        </div>
        <div
          style="width: 200px"
          class="rounded border bg-gray-200 capitalize px-1.5 text-xs font-semibold"
        >
          {{
            payslipInfo.accountNumber === " " || !payslipInfo.accountNumber
              ? "NaN"
              : payslipInfo.accountNumber
          }}
        </div>
      </div>
    </div>
    <div class="text-gray-500 text-xs mb-1.5">
      visit this link to verify your payslip
      <a class="text-black font-bold" :href="payslipUrl">{{ payslipUrl }}</a>
    </div>
    <div class="w-full">
      <table class="w-full border rounded-lg">
        <thead class="text-center">
          <td
            class="bg-black text-white text-xs border border-gray-500 p-1.5"
            colspan="1"
          >
            Subject
          </td>
          <td
            class="bg-black text-white text-xs border border-gray-500 p-1.5"
            colspan="3"
          >
            Description
          </td>
          <td
            class="bg-black text-white text-xs border border-gray-500 p-1.5"
            colspan="1"
          >
            Total
          </td>
        </thead>
        <tbody>
          <tr>
            <td
              style="
                background: #deffde;
                font-size: 12px;
                border: 1px solid lightgray;
                padding: 6px;
              "
              colspan="1"
            >
              BASIC SALARY
            </td>
            <td colspan="3" class="w-[65%]"></td>
            <td
              colspan="1"
              class="text-right font-bold text-xs border border-gray-500 p-1.5"
            >
              {{
                payslipInfo.basicSalary === " " || !payslipInfo.basicSalary
                  ? "0.00"
                  : formatToNaira(payslipInfo.basicSalary)
              }}
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                background: #deffde;
                font-size: 12px;
                border: 1px solid lightgray;
                padding: 6px;
              "
              co
            >
              ALLOWANCES
            </td>
            <td
              colspan="3"
              class="w-[65%] px-2 space-x-1.5 text-right font-bold text-xs border border-gray-500 p-1.5"
            >
              <div
                class="flex flex-wrap gap-1"
                v-if="!payslipInfo.staffType.includes('pensioner')"
              >
                <span
                  v-for="(a, i) in allowances"
                  :key="i"
                  class="border border-gray-500 bg-gray-100 text-xs rounded p-0.5 min-w-[80px]"
                  ><span class="font-bold text-gray-500">{{ a.name }}: </span
                  ><span>{{
                    formatToNaira(a.value.replace("N", ""))
                  }}</span></span
                >
              </div>
            </td>
            <td
              colspan="1"
              class="text-right font-bold text-xs border border-gray-500 p-1.5"
            >
              {{
                payslipInfo.totalAllowance === " " ||
                !payslipInfo.totalAllowance
                  ? "0.00"
                  : formatToNaira(payslipInfo.totalAllowance)
              }}
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                background: #deffde;
                font-size: 12px;
                border: 1px solid lightgray;
                padding: 6px;
              "
              co
            >
              MONTHLY GROSS
            </td>
            <td
              colspan="3"
              class="w-[65%] text-right font-bold text-xs border border-gray-500 p-1.5"
            ></td>
            <td
              colspan="1"
              class="text-right font-bold text-xs border border-gray-500 p-1.5"
            >
              {{ formatToNaira(payslipInfo.gross) ?? "0.00" }}
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                background: #deffde;
                font-size: 12px;
                border: 1px solid lightgray;
                padding: 6px;
              "
              co
            >
              DEDUCTIONS
            </td>
            <td
              colspan="3"
              class="w-[65%] px-2 space-x-1.5 text-right font-bold text-xs border border-gray-500 p-1.5"
            >
              <div
                class="flex flex-wrap gap-1"
                v-if="!payslipInfo.staffType.includes('pensioner')"
              >
                <span
                  v-for="(d, i) in deductions"
                  :key="i"
                  class="border border-gray-500 bg-gray-100 text-xs rounded p-0.5 min-w-[80px]"
                  ><span class="font-bold text-gray-500">{{ d.name }}: </span
                  >{{ formatToNaira(d.value.replace("N", "")) }}</span
                >
              </div>
            </td>
            <td
              colspan="1"
              class="text-right font-bold text-xs border border-gray-500 p-1.5"
            >
              {{
                payslipInfo.totalDeduction === " " ||
                !payslipInfo.totalDeduction
                  ? "0.00"
                  : formatToNaira(payslipInfo.totalDeduction)
              }}
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                background: #deffde;
                font-size: 12px;
                border: 1px solid lightgray;
                padding: 6px;
              "
              co
            >
              OVERPAYMENT
            </td>
            <td
              colspan="3"
              class="w-[65%] text-right font-bold text-xs border border-gray-500 p-1.5"
            ></td>
            <td
              colspan="1"
              class="text-right font-bold text-xs border border-gray-500 p-1.5"
            >
              {{
                payslipInfo.overPayment === " " || !payslipInfo.overPayment
                  ? "0.00"
                  : formatToNaira(payslipInfo.overPayment)
              }}
            </td>
          </tr>
          <tr>
            <td colspan="1" class="bg-black text-white text-xs p-0.5">
              NET PAY
            </td>
            <td colspan="3" class="w-[65%]"></td>
            <td colspan="1" class="text-right font-bold">
              {{
                payslipInfo.net === " " || !payslipInfo.net
                  ? "0.00"
                  : formatToNaira(payslipInfo.net)
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "payslipCard",

  props: {
    payslipInfo: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    deductions() {
      return this.structureData(this.payslipInfo.deductionDetails);
    },
    allowances() {
      return this.structureData(this.payslipInfo.allowanceDetails);
    },
    payslipUrl() {
      return `${window.location.origin}/payslip/${this.payslipInfo._id}`;
    },
  },
  methods: {
    structureData(str) {
      const keyValuePairs = str.split(",");

      const result = keyValuePairs.map((pair) => {
        const [name, value] = pair.trim().split("=");
        return { name, value };
      });

      return result;
    },
    formatToNaira(number) {
      return new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
      }).format(number);
    },
  },
};
</script>

<style></style>
