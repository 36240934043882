<template>
  <transition name="fade" appear>
    <div :class="`${done ? 'bg-[#DEFFDE]' : 'bg-[#F7F7F7]'} rounded-lg p-6`">
      <section v-if="done" class="flex items-start gap-3">
        <div class="checkbox py-1 px-3.5">
          <img
            src="@/assets/images/icons/shield.svg"
            alt="icons"
            class="w-10 h-10"
          />
        </div>

        <div class="text-dkgray">
          <h2 class="font-bold text-2xl">You are all done</h2>

          <p class="mt-3 text-sm">
            Follow the instructions on the summary page with the printout
            provided to obtain your certificate of verification.
          </p>

          <h6
            class="mt-3 font-bold text-purple-800 text-xl"
            v-if="!$store.state.general?.user?.hideSurvey"
          >
            Take a minute to complete this
            <router-link
              class="underline font-black"
              :to="{ name: 'Staff Survey' }"
            >
              Professional Survey
            </router-link>
          </h6>
        </div>
      </section>

      <section v-else class="flex flex-col justify-start items-start">
        <div class="checkbox py-2 px-3">
          <img
            src="@/assets/images/icons/shield.svg"
            alt="icons"
            class="w-6 h-6"
          />
        </div>

        <div class="mt-3 text-dkgray">
          <h6 class="font-semibold">Bio-Data Completion Status</h6>

          <h2 class="font-bold text-4xl">{{ step }} of {{ totalStep }} done</h2>
        </div>

        <div class="mt-6">
          <router-link
            :to="{ name: nxStage }"
            class="px-4 py-2 flex gap-2 bg-oneGreen text-white text-xs rounded"
          >
            <span>Complete your Verification</span> &nbsp;
            <span>&#10148;</span>
          </router-link>
        </div>
      </section>

      <router-link
        v-if="$store.state.general?.user?.gen69Generated"
        class="block w-full rounded-lg text-oneGreen bg-transparent mt-9 text-center py-2 border-2 border-oneGreen text-sm font-black"
        :to="{
          name: 'Staff GEN69',
          params: { genId: $store.state.general?.user?.verificationCode },
        }"
        target="_blank"
      >
        View Gen69
      </router-link>
    </div>
  </transition>
</template>

<script>
export default {
  name: "StatusCard",

  props: {
    vsteps: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      step: this.vsteps.reduce((sm, a) => sm + a, 0),
      nxStage: this.$store.getters["staff/nextStage"],
      totalStep: 8,
    };
  },

  computed: {
    done() {
      return this.step === this.totalStep;
    },
  },
};
</script>

<style scoped>
.checkbox {
  @apply bg-[#BDFCBD] rounded;
}
.disabled {
  opacity: 0.6;
  pointer-events: none;
}
</style>
