<template>
  <div class="font-mulish">
    <section
      class="flex justify-between items-start gap-7 section mt-14 min-h-[460px]"
    >
      <div class="w-full lg:w-7/12 rounded-lg h-[460px] p-px">
        <vue-cal
          class="vuecal--green-theme rounded-lg"
          small
          :time="true"
          active-view="month"
          :disable-views="['years', 'year']"
          :events="events"
          events-on-month-view="short"
          :show-all-day-events="true"
          @view-change="fetchChangeEvents($event)"
        >
        </vue-cal>
      </div>

      <div class="w-full lg:w-5/12 p-4 border border-mdgray rounded-lg">
        <h6 class="text-xs font-bold">Last Clock-In Details</h6>

        <div
          v-if="loading"
          class="min-h-[400px] flex items-center justify-center"
        >
          <spinner class="w-12 h-12" color="gray-500"></spinner>
        </div>

        <template v-else>
          <div class="two-info gray">
            <p>Clock-in Time</p>
            <p>
              {{
                lastClockInDetails.lastClockInTime
                  | moment("YYYY-MM-DD HH:MM:SS")
              }}
            </p>
          </div>

          <div class="two-info gray">
            <p>Clock-Out Time</p>
            <p>
              {{
                lastClockInDetails.lastClockOutTime
                  | moment("YYYY-MM-DD HH:MM:SS")
              }}
            </p>
          </div>

          <div class="two-info gray">
            <p>Location status</p>
            <p>{{ lastClockInDetails.locationStatus }}</p>
          </div>

          <div class="two-info gray">
            <p>Address</p>
            <p
              class="truncate w-2/3"
              title="IPhone 12 pro IPhone 12 pro IPhone 12 pro IPhone 12 pro IPhone 12
              pro IPhone 12 pro"
            >
              {{ lastClockInDetails.address }}
            </p>
          </div>

          <h6 class="text-xs font-bold mt-4">Clock-ins Stats for the month</h6>

          <div class="two-info gray">
            <p>Total</p>
            <p>{{ clockInStatsForTheMonth.totalClockIns }}</p>
          </div>

          <div class="two-info bg-green-100">
            <p>Prompt Clock-ins</p>
            <p>{{ clockInStatsForTheMonth.promptClockIns }}</p>
          </div>

          <div class="two-info bg-yellow-100">
            <p>Late Clock-ins</p>
            <p>{{ clockInStatsForTheMonth.lateClockIns }}</p>
          </div>

          <div class="two-info gray">
            <p>Number of Clock-ins</p>
            <p>{{ clockInStatsForTheMonth.totalClockIns }}</p>
          </div>

          <div class="two-info gray">
            <p>Number of Clock-outs</p>
            <p>{{ clockInStatsForTheMonth.totalClockOuts }}</p>
          </div>

          <!-- <div class="two-info bg-red-200">
            <p>Absent Days</p>
            <p>1</p>
          </div> -->
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
export default {
  name: "StaffClockinStats",

  components: {
    VueCal,
  },

  data() {
    return {
      loading: false,
      clockInStatsForTheMonth: {
        lateClockIns: 1,
        promptClockIns: 1,
        totalClockIns: 2,
        totalClockOuts: 0,
      },
      lastClockInDetails: {
        address:
          "House 46 2nd Dr, Duboyi District, Abuja 420053, Federal Capital Territory, Nigeria",
        lastClockInTime: "08:59:34",
        lastClockOutTime: null,
        locationStatus: "Onsite",
      },
      events: [
        {
          class: "late",
          createdAt: "2024-03-25 16:04",
          end: "2024-03-25 17:22",
          start: "2024-03-25 17:04",
          title:
            "39 2nd Dr, Kaura, Abuja 900103, Federal Capital Territory, Nigeria",
          updatedAt: "2024-03-25 16:22",
        },
      ],
    };
  },

  async created() {
    const startDate = this.moment().startOf("month").format("YYYY-MM-DD");
    const endDate = this.moment().endOf("month").format("YYYY-MM-DD");
    await this.getStaffClockin(startDate, endDate);
  },

  methods: {
    async fetchChangeEvents(events) {
      if (events.view !== "month") return;
      const startDate = this.moment(events.startDate).format("YYYY-MM-DD");
      const endDate = this.moment().format("YYYY-MM-DD");
      await this.getStaffClockin(startDate, endDate);
    },

    async getStaffClockin(startDate, endDate) {
      this.loading = true;

      try {
        const res = await this.$http.get(
          `/setup/office/${this.$store.getters["general/userId"]}/clockin-record/${this.$store.getters["general/userId"]}?startDate=${startDate}&endDate=${endDate}`
        );

        if (!res) {
          this.loading = false;
          return;
        }
        const { data } = res;
        console.log(data);

        this.staff = data.data;
        this.events = data.data.calendarData;
        this.clockInStatsForTheMonth = data.data.clockInStatsForTheMonth;
        this.lastClockInDetails = data.data.lastClockInDetails;
        this.loading = false;
      } catch (error) {
        console.log({ error });
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.section {
  @apply w-full max-w-[800px] mx-auto;
}
.two-info {
  @apply flex justify-between items-center mt-2 py-2 px-1 border border-litgray rounded-lg text-xs text-dkgray;
}
.two-info.gray {
  @apply bg-litergray;
}
.two-info p:first-of-type {
  @apply italic font-medium;
}
.two-info p:last-of-type {
  @apply font-bold text-right;
}
</style>
