<template>
  <div>
    <div v-if="getting" class="flex justify-center h-[50vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <div
      v-else-if="notFound || !printout"
      class="w-full flex flex-col justify-center items-center h-[50vh]"
    >
      <img
        src="@/assets/images/icons/broken_link.svg"
        alt="icon"
        class="w-20 h-20 transform rotate-180"
      />

      <p class="text-red-400 font-bold mt-2">Printout not found</p>
    </div>

    <section v-else>
      <div id="printout" v-html="printout"></div>

      <div class="flex justify-center gap-4 w-full mt-10">
        <div style="width: 35%">
          <button
            @click="printPrintout"
            class="flex justify-center w-full gap-5 py-2.5 rounded border border-darkGreen"
          >
            <img
              src="@/assets/images/icons/print.svg"
              alt="icons"
              style="display: inline"
            />
            <span>Print</span>
          </button>
        </div>

        <div v-if="$route.name == 'Home'" style="width: 35%">
          <button
            @click="closeForm"
            class="border border-gray-400 text-black w-full py-2.5 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Printout",

  data() {
    return {
      getting: false,
      sending: false,
      notFound: true,
      printout: null,
    };
  },

  async created() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.getting = true;
        const verificationCode = this.$route.params.verificationCode;

        const res = await this.$http.post(
          `/employee/${verificationCode}`,
          {
            baseUrl: `${window.location.origin}/staff-printout`,
          },
          {
            headers: { noauth: true },
          }
        );
        this.getting = false;

        if (!res) {
          return;
        }
        this.notFound = false;

        const { data } = res;
        this.printout = data;
      } catch (err) {
        this.getting = false;
      }
    },

    printPrintout() {
      const divContents = document.getElementById("printout").innerHTML;
      const aDocs = window.open("", "", "");
      aDocs.document.write(
        `<html><head><title>${document.title}</title></head>`
      );

      aDocs.document.write("<body>");
      aDocs.document.write(divContents);
      aDocs.document.write("</body></html>");
      aDocs.document.close();
      aDocs.focus();
      setTimeout(() => {
        aDocs.print();
        aDocs.close();
        return true;
      }, 100);
    },
  },
};
</script>

<style scoped>
@media print {
  .singlePrintout {
    page-break-after: always;
  }
}
</style>
