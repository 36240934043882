<template>
  <div class="p-4 lg:px-10 lg:py-7">
    <h3 class="font-bold text-2xl">Professional Survey</h3>

    <ProgressBar :currentStep="step" />

    <Education :education="education" v-if="step === 1" />

    <ProfessionalBody
      :professionalBodies="professionalBodies"
      @change-prof-body="handleHasProfBody"
      v-if="step === 2"
    />

    <Skills :skills="skills" v-if="step === 3" />

    <Hobbies :hobbies="hobbies" v-if="step === 4" />

    <section class="flex justify-between mt-5">
      <button
        :disabled="step <= 1"
        class="bg-gray-500 text-white py-2 px-5 lg:px-14 rounded"
        @click="prev"
      >
        &larr; Back
      </button>

      <button
        v-if="step != 4"
        class="bg-idBlued text-white py-2 px-5 lg:px-14 rounded"
        @click="next"
        :disabled="!nextReady"
      >
        Next &rarr;
      </button>

      <button
        @click="submit"
        :disabled="!nextReady || processing"
        v-else
        class="flex justify-center gap-3 items-center bg-oneGreen text-white py-2 px-5 lg:px-14 rounded"
      >
        <spinner v-if="processing"></spinner>
        Submit Survey
      </button>
    </section>
  </div>
</template>

<script>
import ProgressBar from "@/components/staff/survey/ProgressBar.vue";
import Education from "@/components/staff/survey/Education.vue";
import Skills from "@/components/staff/survey/Skills.vue";
import Hobbies from "@/components/staff/survey/Hobbies.vue";
import ProfessionalBody from "@/components/staff/survey/ProfessionalBody.vue";

const emptyEdu = {
  qualification: "PRIMARY SCHOOL",
  institutionName: null,
  grade: null,
  year: null,
  image: null,
  degree: null,
  uploading: false,
  academicQualification: null,
};

const emptyProf = {
  name: null,
  image: null,
  year: null,
};

export default {
  name: "ProfessionalSurvey",

  components: {
    ProgressBar,
    Education,
    Skills,
    Hobbies,
    ProfessionalBody,
  },

  async created() {
    await this.$store.dispatch("staff/getAcademicCategories");
    await this.$store.dispatch("staff/getProfessionalBodies");
    await this.$store.dispatch("staff/getProfessionalSkills");
  },

  data() {
    return {
      step: 1,
      processing: false,
      hasProfBody: true,
      education: {
        question: "What are your educational backgrounds?",
        answers: [{ ...emptyEdu }],
        isRecord: true,
        name: "educationQualifications",
      },
      professionalBodies: {
        question:
          "Having a membership in a professional body can give you numerous advantages.",
        answers: [
          {
            ...emptyProf,
          },
        ],
        isRecord: true,
        name: "professionalBodies",
      },
      hobbies: {
        question: "What are your hobbies",
        answers: [""],
      },
      skills: {
        question: "Additional skills you possess",
        answers: [],
      },
    };
  },

  computed: {
    nextReady() {
      if (this.step === 1) {
        return this.hasNullValue(this.education.answers);
      }

      if (this.step === 2) {
        return (
          !this.hasProfBody ||
          this.hasNullValue(this.professionalBodies.answers)
        );
      }

      if (this.step === 3) {
        return !this.skills.answers.includes(" ");
      }

      if (this.step === 4) {
        return !this.hobbies.answers.includes(" ");
      }

      return false;
    },
  },

  methods: {
    handleHasProfBody(val) {
      this.hasProfBody = val;
    },
    prev() {
      if (this.step <= 1) return;
      this.step -= 1;
    },
    next() {
      if (this.step >= 4) return;
      this.step += 1;
    },
    reset() {
      this.education.answers = [{ ...emptyEdu }];
      this.hobbies.answers = [];
      this.skills.answers = [];
      this.professionalBodies = [{ ...emptyProf }];
      this.$router.push({ name: "Staff Dashboard" });
    },
    async submit() {
      try {
        this.processing = true;
        const res = await this.$http.post(`survey`, {
          surveyQuestions: [
            this.education,
            this.hasProfBody ? this.professionalBodies : [],
            this.hobbies,
            this.skills,
          ],
          verificationCode: this.$store.state.staff.profile.verificationCode,
          email: this.$store.state.staff.profile.email,
        });
        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.reset();
        this.$store.commit("general/hideSurvey");
        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (error) {
        console.log(error);
      }
    },
    hasNullValue(data) {
      if (Array.isArray(data)) {
        return data.every((obj) =>
          Object.values(obj).every((value) => value !== null)
        );
      }
    },
  },
};
</script>

<style scoped>
.input[type="text"] {
  font-size: 1rem;
  line-height: 1rem;
}
</style>
