<template>
  <div class="pb-10">
    <div class="flex justify-between items-center py-3">
      <h6 class="text-idBlued font-bold">Payment history</h6>

      <v-select
        :options="years"
        :clearable="false"
        v-model="year"
        class="w-4/12 lg:w-3/12"
      ></v-select>
    </div>

    <div
      class="bg-gray-100 h-[120px] w-full flex items-center justify-center"
      v-if="fetching"
    >
      <spinner class="h-[40px] w-[40px]"></spinner>
    </div>

    <table v-else>
      <thead>
        <tr>
          <th>Month</th>
          <th>Basic Salary</th>
          <th>Total Allowance</th>
          <th>Gross</th>
          <th>Over Payment</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody v-if="payload.payslips && payload.payslips.length !== 0">
        <tr v-for="(p, i) in payload.payslips" :key="i">
          <td>
            {{
              Number.isInteger(Number(p.month))
                ? months[Number(p.month) - 1]
                : p.month
            }}
          </td>
          <td>
            {{
              p.basicSalary === " " || !p.basicSalary
                ? "0.00"
                : formatToNaira(p.basicSalary)
            }}
          </td>
          <td>
            {{
              p.totalAllowance === " " || !p.totalAllowance
                ? "0.00"
                : formatToNaira(p.totalAllowance)
            }}
          </td>
          <td>
            {{ p.gross === " " || !p.gross ? "0.00" : formatToNaira(p.gross) }}
          </td>
          <td>
            {{
              p.overPayment === " " || !p.overPayment
                ? "0.00"
                : formatToNaira(p.overPayment)
            }}
          </td>
          <td>
            <button @click="showPayslip(p)">View payslip</button>
          </td>
        </tr>
      </tbody>
      <tbody v-else class="text-center">
        <tr>
          <td colspan="6" class="text-center font-bold">No Data.</td>
        </tr>
      </tbody>
    </table>

    <PayslipModal
      :payslip-info="payslipInfo"
      :user-data="userData"
      @send-email="sendToEmail"
      @close="showPslip = false"
      v-if="showPslip"
      :processing="processing"
    />
  </div>
</template>

<script>
import PayslipModal from "@/components/staff/dashboard/Payslip.vue";
export default {
  name: "TransactionTable",
  components: {
    PayslipModal,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showPslip: false,
      payslipInfo: {},
      fetching: false,
      processing: false,
      userData: {},
      payload: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  watch: {
    year(val) {
      this.fetchPayslips(val);
    },
  },
  computed: {
    years() {
      const years = [];
      for (let y = new Date().getFullYear(); y >= 2020; y--) {
        years.push(y);
      }
      return years;
    },
  },

  mounted() {
    this.fetchPayslips(this.year);
  },

  methods: {
    showPayslip(row) {
      this.payslipInfo = row;
      this.showPslip = true;
    },
    formatToNaira(number) {
      return new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
      }).format(number);
    },
    async sendToEmail() {
      try {
        this.processing = true;
        const res = await this.$http.post(`/veripay/email-payslip`, {
          year: this.payslipInfo.year,
          month: this.payslipInfo.month,
          verificationCode: this.payslipInfo.verificationCode,
          baseUrl: `${window.location.origin}`,
        });

        this.processing = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchPayslips(year) {
      this.fetching = true;
      try {
        const res = await this.$http.post(`/veripay/fetch-payslip`, {
          year: `${year}`,
          baseUrl: `${window.location.origin}`,
        });

        this.fetching = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.payload = data.data;
        this.userData = data.data.employee;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
table {
  @apply w-full;
}
thead tr th {
  @apply px-5 py-4 bg-[#F9FBFF] font-bold text-xs text-dkgray text-left;
}
tbody tr:nth-child(even) td {
  @apply bg-[#F9F9F9];
}
tbody tr td {
  @apply px-5 py-6 text-xs;
}
tbody tr td:first-child {
  @apply font-medium text-idBlued;
}
tbody tr td button {
  @apply text-oneGreen font-bold;
}
thead tr th:nth-child(3),
thead tr th:nth-child(4),
tbody tr td:nth-child(3),
tbody tr td:nth-child(4) {
  @apply hidden lg:table-cell;
}
</style>
