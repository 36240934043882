<template>
  <div>
    <div v-if="getting" class="flex justify-center items-center h-[50vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <div
      v-else-if="notFound || !staffData"
      class="w-full flex flex-col justify-center items-center h-[50vh]"
    >
      <img
        src="@/assets/images/icons/broken_link.svg"
        alt="icon"
        class="w-20 h-20 transform rotate-180"
      />

      <p class="text-red-400 font-bold mt-2">Gen69 not found</p>
    </div>

    <section v-else class="mt-5">
      <div id="printout">
        <Gen69 :staffData="staffData" :getting="getting" />
      </div>

      <div class="flex justify-center gap-4 w-full mt-10 pb-7">
        <div style="width: 35%">
          <button
            @click="printPrintout"
            class="flex justify-center w-full gap-5 py-2.5 rounded text-white bg-idBlued"
          >
            <Icon icon="mdi:printer" class="w-7 h-7" />
            <span>Print</span>
          </button>
        </div>

        <div v-if="$route.name == 'Home'" style="width: 35%">
          <button
            @click="closeForm"
            class="border border-gray-400 text-black w-full py-2.5 rounded"
          >
            Close
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import Gen69 from "@/components/admin/staff/Gen69.vue";
export default {
  name: "StaffGen69",

  components: {
    Gen69,
    Icon,
  },

  data() {
    return {
      getting: false,
      sending: false,
      notFound: true,
      staffData: null,
    };
  },

  async created() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.getting = true;
        const genId = this.$route.params.genId;

        const res = await this.$http.post(
          `service-staff/scan-gen69/${genId}`,
          {
            baseUrl: `${window.location.origin}/gen69`,
          },
          {
            headers: { noauth: true },
          }
        );
        this.getting = false;

        if (!res) {
          return;
        }
        this.notFound = false;

        const { data } = res;
        this.staffData = data.data;
      } catch (err) {
        this.getting = false;
      }
    },

    printPrintout() {
      const mywindow = window.open("", "", "");
      const divContents = document.getElementById("printout").innerHTML;
      mywindow.document.write("<html><head><title></title>");
      mywindow.document.write(
        ' <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css">'
      );
      mywindow.document.write("</head><body>");

      const links = document.querySelectorAll("style");
      links.forEach(function (link) {
        mywindow.document.head.appendChild(link.cloneNode(true));
      });

      mywindow.document.write("<div style='margin-top:20px;'></div>");
      mywindow.document.write(divContents);
      mywindow.document.write("</body></html>");
      mywindow.document.close();
      mywindow.focus();
      setTimeout(() => {
        mywindow.print();
        mywindow.close();
        return true;
      }, 1000);
    },
  },
};
</script>

<style scoped>
@media print {
  .singlePrintout {
    page-break-after: always;
  }
}
</style>
