<template>
  <div class="flex justify-between divide-x px-4 pt-5 lg:px-12 lg:pt-10">
    <!-- left side -->
    <div class="w-full lg:w-8/12 lg:pr-10">
      <section class="flex justify-between items-center">
        <div class="flex items-center gap-6">
          <div>
            <img
              src="@/assets/images/null_female.png"
              alt="user profile pics"
              class="w-12 h-12 lg:w-14 lg:h-14 rounded-lg border object-contain"
            />
          </div>

          <div>
            <h5 class="text-xs font-medium text-idBlued">Welcome</h5>
            <h1 class="text-3pxl font-bold text-idBlued">
              {{ $store.getters["general/username"] }}
            </h1>
          </div>
        </div>

        <div class="block lg:hidden">
          <button @click="toggleStatus">
            <img
              src="@/assets/images/icons/info_dash.svg"
              alt="icons"
              class="w-8 h-8"
            />
          </button>
        </div>

        <!-- modal -->
        <div
          v-if="showStatModal"
          class="fixed inset-0 bg-black/40 flex flex-col justify-start items-end"
          style="z-index: 10 !important"
        >
          <div class="mt-24 mr-4">
            <button @click="toggleStatus" class="bg-white rounded p-2">
              <img
                src="@/assets/images/icons/close.svg"
                alt="icons"
                class="w-8 h-8"
              />
            </button>
          </div>

          <div class="mt-1 mr-4 ml-auto w-11/12">
            <StatusCard />
          </div>
        </div>
      </section>

      <section class="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-6">
        <small-box :fetching="getting" class="bg-[#F4F4F4]">
          <template #title>DESIGNATION</template>
          <template #value>
            {{ d.designation }}
          </template>
        </small-box>

        <small-box :fetching="getting" class="bg-[#DEFFDE]">
          <template #title>MDA</template>
          <template #value>{{ d.mdaName }} </template>
        </small-box>

        <small-box :fetching="getting" class="bg-[#F4F4F4] capitalize">
          <template #title>CADRE</template>
          <template #value>{{ d.cadre }}</template>
        </small-box>

        <small-box :fetching="getting" class="bg-[#F4F4F4]">
          <template #title>DATE OF LAST PROMOTION</template>
          <template #value>{{
            d.dateOfLastPromotion | moment("Do MMM YYYY")
          }}</template>
        </small-box>

        <small-box :fetching="getting" class="bg-[#E2F1FF]">
          <template #title>DATE OF EMPLOYMENT</template>
          <template #value>{{
            d.dateOfAppointment | moment("Do MMM YYYY")
          }}</template>
        </small-box>

        <small-box :fetching="getting" class="bg-[#FFECEC]">
          <template #title>DATE OF RETIREMENT</template>
          <template #value>{{
            d.dateOfRetirement | moment("Do MMM YYYY")
          }}</template>
        </small-box>
      </section>

      <section class="mt-8">
        <TransactionTable />
      </section>
    </div>

    <!-- right side -->
    <div class="hidden lg:flex flex-col divide-y w-4/12">
      <section class="pl-7 pb-7">
        <section
          v-if="getting"
          class="flex justify-center items-center h-[20vh]"
        >
          <spinner color="oneGreen" class="mt-2 w-12 h-12" />
        </section>

        <StatusCard v-else :vsteps="profile.verificationStep" />
      </section>

      <section>
        <UserGuide :instructions="instructions" />
      </section>
    </div>
  </div>
</template>

<script>
import SmallBox from "@/components/staff/dashboard/SmallBox.vue";
import TransactionTable from "@/components/staff/dashboard/TransactionTable.vue";
import StatusCard from "@/components/staff/dashboard/StatusCard.vue";
import UserGuide from "@/components/staff/dashboard/UserGuide.vue";
import { fetchStaffProfile } from "@/services/sourceData";
export default {
  name: "StaffDashboard",

  components: {
    SmallBox,
    TransactionTable,
    StatusCard,
    UserGuide,
  },

  data() {
    return {
      showStatModal: false,
      getting: true,
      d: {
        cadre: null,
        dateOfAppointment: null,
        dateOfLastPromotion: "N/A",
        dateOfRetirement: "N/A",
        departmentName: "GHUM",
        designation: "N/A",
        mdaName: null,
      },
      instructions: {
        1: "Begin your verification process by clicking on the <span class='font-bold'>Staff verification</span> menu. There are a total of 7 steps to complete",
        2: "Start by completing the first 5 levels of your bio data.",
        3: "Cross check every information presented and update the fields that require an update.",
        4: "The 5th stage requires you to confirm your salary account details",
        5: "After the first 5 stages, you proceed to <span class='font-bold'>Document upload</span>. Upload every document indicated according to the specifications to complete the 6th stage.",
        6: "The 7th and final stage requires you to complete a BVN verification in order for you to complete the online verification process.",
        7: "Finally, print out two copies of the veification printout and proceed to government designated location on the schedule date with originals of all your documents for sighting and physical verifications.",
        8: "Please note that you can always stop at any stage and return to continue at any point.",
      },
      profile: {
        verificationStep: [],
      },
    };
  },

  async created() {
    const resp = await fetchStaffProfile();
    this.profile = resp;

    this.$store.commit("staff/saveData", {
      key: "profile",
      data: resp,
    });
    await this.getData();

    this.getting = false;
    this.$store.dispatch("staff/getDocSetup");
    this.$store.dispatch("staff/getNextOfKin");
    this.$store.dispatch("staff/getEducation");
    this.$store.dispatch("staff/getEmploymentInfo");
    this.$store.dispatch("staff/getSalaryAccount");
    this.$store.dispatch("staff/getCadres");
    this.$store.dispatch("staff/getPensionInfo");
    // this.$store.dispatch("staff/getSalaryGradeLevels");
    // this.$store.dispatch("staff/getGradeSteps");
    this.$store.dispatch("staff/getPfas");
    this.$store.dispatch("staff/getMdas");
    this.$store.dispatch("staff/getDesignations");
    this.$store.dispatch("staff/getDepartments");
    this.$store.dispatch("staff/getUnions");
    this.$store.dispatch("staff/getAcademicCategories");
    this.$store.dispatch("staff/getProfessionalBodies");
    this.$store.dispatch("staff/getProfessionalSkills");
  },

  methods: {
    toggleStatus() {
      this.showStatModal = !this.showStatModal;
    },

    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get("/e/dashboard");

        if (!res) {
          return;
        }

        const { data } = res;
        this.d = data.data;
      } catch (err) {
        this.getting = false;
      }
    },
  },
};
</script>

<style scoped></style>
