<template>
  <div>
    <div v-if="getting" class="flex justify-center h-[100vh] items-center">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <div
      v-else-if="notFound || !payslipInfo"
      class="w-full flex flex-col justify-center items-center h-[50vh]"
    >
      <img
        src="@/assets/images/icons/broken_link.svg"
        alt="icon"
        class="w-20 h-20 transform rotate-180"
      />

      <p class="text-red-400 font-bold mt-2">Payslip not found</p>
    </div>
    <div class="flex items-center justify-center h-screen" v-else>
      <div class="m-auto flex gap-2 lg:overflow-hidden overflow-x-scroll">
        <Payslip
          :userData="this.$store.state.staff.profile"
          :payslipInfo="payslipInfo"
        />
        <div class="h-full space-y-2">
          <button @click="download" class="">Download</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Payslip from "@/components/staff/dashboard/PayslipCard.vue";
export default {
  name: "StaffPaylipScanResult",
  components: {
    Payslip,
  },
  data() {
    return {
      getting: false,
      notFound: true,
      payslipInfo: null,
    };
  },
  mounted() {
    this.getPayslip();
  },
  computed: {
    deductions() {
      return this.structureData(this.payslipInfo.deductionDetails);
    },
    allowances() {
      return this.structureData(this.payslipInfo.allowanceDetails);
    },
  },
  methods: {
    structureData(str) {
      const keyValuePairs = str.split(",");

      const result = keyValuePairs.map((pair) => {
        const [name, value] = pair.trim().split("=");
        return { name, value };
      });

      return result;
    },
    download() {
      const mywindow = window.open("", "", "");
      const divContents = document.getElementById("payslip").innerHTML;
      mywindow.document.write("<html><head><title></title>");
      mywindow.document.write(
        ' <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css">'
      );

      mywindow.document.write(
        '</head><body style=""><div style="width: 830px;  margin: 0 auto;">'
      );
      mywindow.document.write(divContents);
      mywindow.document.write("</div></body></html>");
      mywindow.document.close();
      mywindow.focus();
      setTimeout(() => {
        mywindow.print();
        mywindow.close();
        return true;
      }, 1000);
    },
    async getPayslip() {
      this.getting = true;
      const payslipId = this.$route.params.payslipId;

      const res = await this.$http.post(
        `/veripay/payslip/${payslipId}`,
        { baseUrl: `${window.location.origin}` },
        {
          headers: { noauth: true },
        }
      );

      this.getting = false;

      if (!res) {
        return;
      }

      this.notFound = false;

      const { data } = res;

      this.payslipInfo = data.data;
    },
  },
};
</script>

<style scoped>
@media print {
  .singlePrintout {
    page-break-after: always;
  }
}
.labeltx {
  @apply rounded border border-oneGreen text-oneGreen uppercase px-1.5 text-xs font-bold;
}

.valuetx {
  @apply rounded border bg-gray-200 capitalize  px-1.5 text-xs font-semibold;
}

.col-black {
  @apply bg-black text-white text-xs p-0.5;
}

.col-green {
  @apply bg-[#DEFFDE] text-xs  p-1.5;
}

table {
  border-collapse: collapse;
}

table td {
  @apply text-xs border border-gray-500 p-1.5;
}

button {
  @apply rounded border border-oneGreen text-xs font-bold  p-2 w-full bg-gray-100 text-oneGreen min-w-[100px];
}
</style>
