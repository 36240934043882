<template>
  <div class="mt-6 lg:mt-10 px-6 w-full max-w-[500px] mx-auto">
    <section>
      <h2 class="font-bold text-idBlued text-xl lg:text-3pxl">
        Change Password
      </h2>

      <form @submit.prevent="proceed">
        <div class="mt-5">
          <label for="oldPassword">Old Password</label>
          <input
            id="oldPassword"
            type="password"
            v-model="form.oldPassword"
            class="input"
            placeholder="enter old password"
          />
        </div>

        <div class="mt-4">
          <label for="newPassword">New Password</label>
          <input
            id="newPassword"
            type="password"
            v-model="form.newPassword"
            class="input"
            placeholder="enter new password"
          />
          <small
            v-if="form.newPassword && form.newPassword.length < 6"
            class="req text-xs font-bold"
          >
            Password length must be up to 6 characters
          </small>
        </div>

        <div class="mt-4">
          <label for="confirmPassword">Confirm Password</label>
          <input
            id="confirmPassword"
            type="password"
            v-model="form.confirmPassword"
            class="input"
            placeholder="re-enter new password"
          />
          <small
            v-if="form.confirmPassword && !passwordMatch"
            class="req text-xs font-bold"
          >
            New Password and Confirm password must match
          </small>
        </div>

        <div class="mt-7">
          <button
            type="submit"
            class="bg-oneGreen text-white rounded-lg py-4 text-sm lg:text-2pxl font-semibold w-full flex justify-center items-center gap-4"
            :disabled="proceeding || !formReady"
          >
            <spinner class="" v-if="proceeding"></spinner>
            <span>Change Password</span>
          </button>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name: "ChangePassword",

  data() {
    return {
      form: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      },
      proceeding: false,
    };
  },

  computed: {
    formReady() {
      return (
        this.form.oldPassword &&
        this.form.newPassword &&
        this.form.confirmPassword
      );
    },
    passwordComplete() {
      return (
        this.form.oldPassword.length >= 6 &&
        this.passwordMatch &&
        this.form.confirmPassword
      );
    },
    passwordMatch() {
      return (
        this.form.newPassword &&
        this.form.newPassword.length >= 6 &&
        this.form.newPassword === this.form.confirmPassword
      );
    },
  },

  methods: {
    async proceed() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all input",
        });
        return;
      }

      try {
        this.proceeding = true;
        const res = await this.$http.post("/auth/e/change-password", this.form);

        this.proceeding = false;
        if (!res) {
          return;
        }
        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$router.push({ name: "Staff Dashboard" });
      } catch (error) {
        this.proceeding = false;
      }
    },
  },
};
</script>
