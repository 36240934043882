<template>
  <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
    <!-- Progress bar -->
    <div class="w-full bg-gray-200 rounded-full">
      <div
        class="h-2 bg-indigo-600 rounded-full"
        :style="{ width: progressPercentage }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",

  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // currentStep: 1,
      totalSteps: 4,
    };
  },
  computed: {
    progressPercentage() {
      return `${(this.currentStep / this.totalSteps) * 100}%`;
    },
  },
};
</script>
