<template>
  <div class="font-mulish">
    <StaffHeader />

    <section class="flex justify-between relative mt-[75px] lg:mt-[82px] z-1">
      <StaffSideNav
        v-if="$store.state.general.sideOpen"
        class="nav-small block lg:hidden w-[242px]"
      />

      <StaffSideNav class="hidden lg:block nav-big w-[242px]" />

      <main class="w-full lg:w-[calc(100%-200px)] bg-white">
        <router-view></router-view>
      </main>
    </section>
  </div>
</template>

<script>
import StaffHeader from "@/components/staff/element/Header.vue";
import StaffSideNav from "@/components/staff/element/SideNav.vue";
export default {
  name: "Scaffold",

  components: {
    StaffHeader,
    StaffSideNav,
  },

  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style scoped>
.nav-small {
  height: calc(100vh - 74px);
}
.nav-big {
  height: calc(100vh - 80px);
}
main {
  height: calc(100vh - 90px);
  overflow-y: scroll;
}
</style>
