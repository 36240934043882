<template>
  <div class="boxx">
    <h6>Use Guide</h6>

    <!-- red box -->
    <div class="red-box">
      <ul>
        <li v-for="(instr, i) in instructions" :key="i">
          <div class="dark-red-box">{{ i }}</div>

          <div class="text" v-html="instr"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserGuide",

  props: {
    instructions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.boxx {
  padding: 1.75rem;
}
h6 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.3125rem;
  color: #808080;
  margin-bottom: 1.5rem;
}
.red-box {
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 1.25rem;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  display: flex;
  justify-content: space-between;
}
li:not(:last-child) {
  margin-bottom: 16px;
}
.dark-red-box {
  @apply text-dkgray bg-[#e2f1ff] font-bold flex flex-col justify-center items-center;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  padding: 0.6875rem 1.3125rem;
  font-size: 1.25rem;
  line-height: 1.625rem;
}
.text {
  @apply text-dkgray font-medium pl-2;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
</style>
