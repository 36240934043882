<template>
  <fieldset class="mt-5 border border-idBluedLight rounded-lg px-4 py-2">
    <legend class="font-bold text-idBlued pr-2">
      {{ skills.question }}
    </legend>

    <div class="w-[450px] text-black">
      <label> Skills <span class="req">*</span> </label>
      <multiselect
        class="text-black"
        v-model="skills.answers"
        :options="skillsArr"
        :searchable="true"
        :close-on-select="false"
        :multiple="true"
        :show-labels="false"
        placeholder="select skills"
      >
      </multiselect>
    </div>
  </fieldset>
</template>

<script>
import { fetchProfessionalSkills } from "@/services/sourceData";

export default {
  name: "SkillsSurvey",

  props: {
    skills: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      skillsArr: [],
    };
  },

  computed: {},
  async beforeMount() {
    const skillsArr = await fetchProfessionalSkills();
    this.skillsArr = skillsArr.map((el) => el.skillsName);
  },
  methods: {},
};
</script>

<style scoped>
.input[type="text"] {
  font-size: 1rem;
  line-height: 1rem;
}
</style>
