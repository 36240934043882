<template>
  <section class="fixed top-0 left-0 h-full w-full z-100">
    <div class="relative h-full w-full">
      <div class="h-full w-full bg-gray-500 opacity-90"></div>
      <div
        class="flex items-start justify-center top-0 left-0 absolute p-2 rounded-lg h-[100vh] w-[100vw]"
      >
        <div class="m-auto flex gap-2 lg:overflow-hidden overflow-x-scroll">
          <Payslip :payslipInfo="payslipInfo" :userData="userData" />
          <div class="h-full space-y-2">
            <button
              @click="close"
              class="rounded border border-green-700 text-xs font-bold p-2 w-full bg-gray-100 text-green-700 min-w-[100px]"
            >
              Close
            </button>
            <button
              @click="download"
              class="rounded border border-green-700 text-xs font-bold p-2 w-full bg-gray-100 text-green-700 min-w-[100px]"
            >
              Download
            </button>
            <button
              @click="sendToEmail"
              :disabled="processing"
              class="flex justify-center gap-3 items-center rounded border border-green-700 text-xs font-bold p-2 w-full bg-oneGreen text-white min-w-[100px]"
            >
              <spinner class="text-oneGreen" v-if="processing"></spinner>
              Send To Email
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Payslip from "@/components/staff/dashboard/PayslipCard.vue";
export default {
  name: "StaffPayslip",
  components: { Payslip },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    payslipInfo: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    sendToEmail() {
      this.$emit("send-email");
    },
    download() {
      const mywindow = window.open("", "", "");
      const divContents = document.getElementById("payslip").innerHTML;
      mywindow.document.write("<html><head><title></title>");
      mywindow.document.write(
        ' <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css">'
      );

      mywindow.document.write(
        '</head><body style=""><div style="width: 830px;  margin: 0 auto;">'
      );
      mywindow.document.write(divContents);
      mywindow.document.write("</div></body></html>");
      mywindow.document.close();
      mywindow.focus();
      setTimeout(() => {
        mywindow.print();
        mywindow.close();
        return true;
      }, 1200);
    },
  },
};
</script>

<style scoped>
.labeltx {
  @apply rounded border border-green-700 text-green-700 uppercase px-1.5 text-xs font-bold;
}

.valuetx {
  @apply rounded border bg-gray-200 capitalize  px-1.5 text-xs font-semibold;
}

.col-black {
  @apply bg-black text-white text-xs p-0.5;
}

.col-green {
  @apply bg-[#DEFFDE] text-xs  p-1.5;
}

table {
  border-collapse: collapse;
}

table td {
  @apply text-xs border border-gray-500 p-1.5;
}

button {
  @apply rounded border border-green-700 text-xs font-bold  p-2 w-full bg-gray-100 text-green-700 min-w-[100px];
}
</style>
