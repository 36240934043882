<template>
  <transition name="slidex" appear="">
    <nav
      class="bg-sideBlack absolute lg:static left-0 bottom-0 top-px font-source"
    >
      <div class="relative h-[calc(100vh-200px)]">
        <ul class="mt-18">
          <li class="mt-5">
            <router-link
              :to="{ name: 'Staff Dashboard' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <img
                src="@/assets/images/icons/dashboard.svg"
                alt="icons"
                class="h-6 w-6"
              />
              <span>Dashboard</span>
            </router-link>
          </li>

          <li v-if="isPension">
            <router-link
              :to="{ name: this.$store.getters['staff/nextStage'] }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <img
                src="@/assets/images/icons/support.svg"
                alt="icons"
                class="h-6 w-6"
              />
              <span>Pension Data</span>
            </router-link>
          </li>

          <li v-else>
            <router-link
              :to="{ name: this.$store.getters['staff/nextStage'] }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <Icon icon="clarity:form-line" class="w-7 h-7" :inline="true" />
              <span>Staff Verification</span>
            </router-link>
          </li>

          <li v-if="!isPension">
            <router-link
              :to="{ name: 'Staff Clock-in History' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <Icon icon="mdi:history" class="w-7 h-7" :inline="true" />

              <span>Clock-In History</span>
            </router-link>
          </li>

          <li>
            <router-link
              :to="{ name: 'Staff Change Password' }"
              active-class="nav-active"
              @click.native="closeNav"
            >
              <img
                src="@/assets/images/icons/settings.svg"
                alt="icons"
                class="h-6 w-6"
              />
              <span>Change Password</span>
            </router-link>
          </li>
        </ul>

        <div class="pl-6 pb-10 absolute bottom-0 right-0 left-0">
          <button
            @click="logout"
            class="flex items-center gap-3 rounded text-white border border-white px-4 py-2.5"
          >
            <img src="@/assets/images/icons/logout.svg" alt="icons" />

            <span class="text-xs">Log out</span>
          </button>
        </div>
      </div>
    </nav>
  </transition>
</template>

<script>
import { Icon } from "@iconify/vue2";
export default {
  name: "StaffSideNav",

  components: { Icon },

  computed: {
    isPension() {
      return (
        Boolean(this.$store.state.staff) &&
        this.$store.state.staff.profile &&
        this.$store.state.staff.profile.staffType &&
        this.$store.state.staff.profile.staffType.includes("pension")
      );
    },
  },

  methods: {
    closeNav() {
      this.$store.commit("general/toggleNavBar", false);
    },
    logout() {
      this.$router.push({ name: "Login" });
      setTimeout(() => {
        this.$store.dispatch("general/logout");
      }, 200);
    },
  },
};
</script>

<style scoped>
nav {
  box-shadow: 0px 0px 4px 0px #a6a5a5;
  z-index: 9;
}
/* ul li {
  @apply mt-2;
}
a {
  @apply text-white hover:bg-[#3B4A52] hover:text-white flex gap-3 px-6 py-3 mt-1;
}
.nav-active {
  @apply bg-[#3B4A52] text-white font-semibold;
} */

ul li {
  @apply mt-2;
}
a {
  @apply text-white rounded text-sm hover:bg-[#3B4A52] hover:text-white flex gap-3 px-3 py-2.5;
}
.nav-active {
  @apply bg-[#3B4A52] text-white font-semibold rounded;
}
li.dropdown {
  @apply border-none text-white rounded text-sm;
}
li.dropdown li {
  @apply border-l border-litgray rounded-none mt-2 hover:bg-[#3B4A52] hover:text-white ml-4;
}
li.dropdown .dropdownDivider {
  display: none;
}
li.dropdown:focus-within .dropdownDivider {
  display: block;
  transition: 0.2s all ease-in-out;
}
li.dropdown:focus-within .icon {
  transform-origin: center;
  transform: rotateX(180deg) translateY(4px);
  transition: 0.2s all ease-in-out;
}

/* ****************** Slide transistion */
.slidex-leave-active,
.slidex-enter-active {
  transition: 0.5s;
}
.slidex-enter {
  transform: translate(-100%, 0);
}
.slidex-leave-to {
  transform: translate(-100%, 0);
}
</style>
