<template>
  <fieldset class="mt-5 border border-idBluedLight rounded-lg px-4 py-2">
    <legend class="font-bold text-idBlued pr-2">
      {{ hobbies.question }}
    </legend>

    <small class="text-purple-500 font-bold text-sm">
      For example: reading, cycling, cooking
    </small>

    <div class="grid grid-cols-1 lg:grid-cols-4 items-center my-5 gap-4">
      <section v-for="(hob, i) in hobbies.answers" :key="i" class="my-5">
        <div class="flex gap-2 items-end">
          <div class="w-full">
            <label :key="`hobbies${i}`"
              >Enter Hobby {{ i + 1 }} <span class="req">*</span>
            </label>

            <input
              type="text"
              class="input"
              :id="`hobbies${i}`"
              v-uppercase
              v-model="hobbies.answers[i]"
            />
          </div>
          <span
            v-if="i !== 0"
            @click="removeHobby(i)"
            class="text-red-500 font-bold cursor-pointer px-2 py-0.5"
            >&#10005;</span
          >
        </div>
      </section>
    </div>

    <div class="flex justify-end mt-3">
      <button
        class="px-5 lg:px-9 py-2 rounded bg-oneGreen text-white"
        @click="addNewHobby"
      >
        + Add another record
      </button>
    </div>
  </fieldset>
</template>

<script>
// import {

// } from "@/services/sourceData";

export default {
  name: "HobbiesSurvey",

  props: {
    hobbies: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    addNewHobby() {
      this.hobbies.answers.push("");
    },
    removeHobby(i) {
      this.hobbies.answers.splice(i, 1);
    },
  },
};
</script>

<style scoped>
.input[type="text"] {
  font-size: 1rem;
  line-height: 1rem;
}
</style>
