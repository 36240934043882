<template>
  <fieldset class="mt-5 border border-idBluedLight rounded-lg px-4 py-2">
    <legend class="font-bold text-idBlued pr-2">
      {{ education.question }}
    </legend>

    <small class="text-purple-500 font-bold lg:text-lg"
      >Completion of Primary School academic entries is required before adding
      any other qualification obtained. eg secondary or tertiary
      education</small
    >

    <div class="flex flex-col divide-y-2 gap-4">
      <section v-for="(edu, i) in education.answers" :key="i" class="my-5">
        <div class="w-full grid grid-cols-1 lg:grid-cols-3 items-center gap-4">
          <div>
            <label :for="`qualification${i}`">
              Academic Institution Level <span class="req">*</span>
            </label>
            <v-select
              :id="`qualification${i}`"
              label="academyName"
              :reduce="(b) => b.academyName"
              :options="$store.state.staff.academicCategories"
              placeholder="-- select --"
              :disabled="i === 0"
              v-model="edu.qualification"
              @input="getDegree"
            >
            </v-select>
          </div>

          <div>
            <label :for="`institutionName${i}`">
              Academic Institution Name <span class="req">*</span>
            </label>
            <input
              type="text"
              class="input"
              placeholder="LAGOS STATE UNIVERISTY"
              :id="`institutionName${i}`"
              v-uppercase
              v-model="edu.institutionName"
            />
          </div>

          <div>
            <label :for="`degree${i}`">
              Academic Degree <span class="req">*</span>
            </label>
            <v-select
              :id="`degree${i}`"
              label="degreeName"
              :reduce="(b) => b.degreeName"
              :options="academicDegrees"
              placeholder="-- select --"
              v-model="edu.degree"
              :disabled="!edu.qualification"
              @input="getAcademicQualification"
            >
            </v-select>
          </div>

          <div>
            <label :for="`academicQualification${i}`">
              Academic Qualifications <span class="req">*</span>
            </label>
            <v-select
              :id="`academicQualification${i}`"
              label="qualificationName"
              :reduce="(b) => b.qualificationName"
              :options="academicQualifications"
              placeholder="-- select --"
              v-model="edu.academicQualification"
              :disabled="!edu.degree"
            >
            </v-select>
          </div>

          <div>
            <label :for="`grade${i}`">Grade<span class="req">*</span> </label>
            <v-select
              :id="`grade${i}`"
              v-uppercase
              v-model="edu.grade"
              :options="academicGrades"
              placeholder="select grade"
            />
          </div>

          <div>
            <label :for="`year${i}`">
              Year of graduation<span class="req">*</span>
            </label>
            <v-select
              :id="`year${i}`"
              :options="years"
              v-uppercase
              v-model="edu.year"
            ></v-select>
          </div>

          <div class="relative col-span-2">
            <label
              class="text-sm"
              :for="`image${i}`"
              title="Upload your certification"
            >
              Certificate Upload
              <span class="req">*</span>
            </label>
            <input
              type="file"
              class="w-full rounded-lg border border-litgray py-1.5 px-3"
              :id="`image${i}`"
              accept="application/pdf"
              :disabled="!edu.qualification || edu.uploading"
              @change="(e) => uploadDoc(e, edu)"
            />
            <p class="flex justify-between">
              <small class="text-idBlued">
                <span v-if="!edu.image">
                  Accepted file type is <b>pdf</b>. Not more than 2mb
                </span>
                <a
                  v-else
                  :href="edu.image"
                  download=""
                  v-uppercase
                  class="underline text-xs"
                  >{{ edu.academicQualification }}.pdf</a
                >
              </small>

              <small class="text-oneGreen font-semibold">
                <spinner v-if="edu.uploading" color="gray-500" class="w-4 h-4"
                  >Loading</spinner
                >
                <span v-if="edu.image">uploaded ✅</span>
              </small>
            </p>
          </div>
        </div>

        <span
          v-if="i !== 0"
          @click="removeEdu(i)"
          class="text-white bg-red-500 font-bold cursor-pointer px-2 py-0.5 border rounded mt-2 inline-block"
          >remove</span
        >
      </section>
    </div>

    <div class="flex justify-end mt-3">
      <button
        class="px-5 lg:px-9 py-2 rounded bg-oneGreen text-white"
        @click="addNewEdu"
      >
        + Add another record
      </button>
    </div>
  </fieldset>
</template>

<script src="https://sdk.amazonaws.com/js/aws-sdk-2.813.0.min.js"></script>
<script>
import {
  fetchAcademicDegrees,
  fetchAcademicQualification,
} from "@/services/sourceData";
import { AcademicGrades } from "@/services/staffProfile";
const emptyEdu = {
  qualification: null,
  institutionName: null,
  grade: null,
  year: null,
  image: null,
  degree: null,
  uploading: false,
  academicQualification: null,
};

export default {
  name: "EducationSurvey",

  props: {
    education: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      academicDegrees: [],
      academicQualifications: [],
      academicGrades: AcademicGrades,
    };
  },

  computed: {
    years() {
      const years = [];
      let y = new Date().getFullYear();
      const stp = y - 50;
      for (; y >= stp; y--) {
        years.push(y);
      }
      return years;
    },
  },

  mounted() {
    this.getAcademicQualification("PRIMARY SCHOOL");
    this.getDegree("PRIMARY SCHOOL");
  },

  methods: {
    async getDegree(name) {
      const academy = this.$store.state.staff.academicCategories.find(
        (acad) => acad.academyName === name
      );
      this.academicDegrees = await fetchAcademicDegrees(academy.academyCode);
    },
    async getAcademicQualification(name) {
      const degree = this.academicDegrees.find(
        (acad) => acad.degreeName === name
      );
      this.academicQualifications = await fetchAcademicQualification(
        degree.degreeCode
      );
    },
    addNewEdu() {
      this.education.answers.push({ ...emptyEdu });
    },
    removeEdu(i) {
      this.education.answers.splice(i, 1);
    },

    slugify(text) {
      return text
        .toString()
        .trim()
        .toUpperCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w\-]+/g, "") // Remove all non-word characters
        .replace(/\-\-+/g, "-"); // Replace multiple - with single -
    },
    async uploadDoc(e, edu) {
      AWS.config.region = this.AWS_REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.AWS_IDENTITY_POOL,
      });

      const fileSizeLimit = 2 * 1024 ** 2; // 2MB

      let file = e.target.files[0];
      if (file.size > fileSizeLimit) {
        this.$swal({
          icon: "error",
          text: "The selected file is more than 2mb",
        });
        file = null;
        return;
      }

      const verificationCode =
        this.$store.state?.staff?.profile?.verificationCode;
      const eduName = this.slugify(edu.qualification);
      const key = `education-survey/${verificationCode}_${eduName}.pdf`;
      console.log(
        { key, bucket: this.AWS_BUCKET },
        process.env.VUE_APP_AWS_BUCKET
      );
      const params = {
        Bucket: this.AWS_BUCKET,
        Key: key,
        Body: file,
      };

      try {
        edu.uploading = true;
        const upload = new AWS.S3.ManagedUpload({ params });
        const resp = await upload.promise();
        console.log({ resp });
        edu.image = resp.Location;
        edu.uploading = false;
        delete edu.uploading;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.input[type="text"] {
  font-size: 1rem;
  line-height: 1rem;
}
</style>
