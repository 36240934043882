<template>
  <fieldset class="mt-5 border border-idBluedLight rounded-lg px-4 py-2">
    <legend class="font-bold text-idBlued pr-2">
      {{ professionalBodies.question }}
    </legend>

    <div class="flex flex-col divide-y-2 gap-4">
      <div>
        <p class="font-bold">Are you a member of any Professional Body?</p>
        <p class="flex gap-5 mt-2">
          <label>
            <input type="radio" :value="true" v-model="hasProfBody" /> Yes
          </label>
          <label>
            <input type="radio" :value="false" v-model="hasProfBody" /> No
          </label>
        </p>
      </div>

      <div v-if="hasProfBody">
        <div class="flex flex-col divide-y-2 gap-4">
          <section
            v-for="(prof, i) in professionalBodies.answers"
            :key="i"
            class="my-5"
          >
            <div
              class="w-full grid grid-cols-1 lg:grid-cols-8 items-start gap-4"
            >
              <div class="col-span-3">
                <label class="text-sm" :for="`professionName${i}`">
                  Select the Professional Body <span class="req">*</span>
                </label>
                <v-select
                  :id="`professionName${i}`"
                  label="professionalBodyName"
                  :reduce="(b) => b.professionalBodyName"
                  :options="$store.state.staff.professionalBodies"
                  placeholder="-- select --"
                  v-model="prof.name"
                >
                </v-select>
              </div>

              <div>
                <label :for="`year${i}`" class="text-sm">
                  Date<span class="req">*</span>
                </label>
                <input
                  type="date"
                  class="input"
                  :id="`year${i}`"
                  v-model="prof.year"
                />
                <!-- <v-select
                  :id="`year${i}`"
                  :options="years"
                  v-uppercase
                  v-model="prof.year"
                ></v-select> -->
              </div>

              <div class="col-span-2">
                <label :for="`membershipNumber${i}`" class="text-sm">
                  Membership Number
                  <span class="req">*</span>
                </label>
                <input
                  type="text"
                  class="input"
                  :id="`membershipNumber${i}`"
                  v-uppercase
                  v-model="prof.membershipNumber"
                />
              </div>

              <div class="relative col-span-2">
                <label
                  class="text-sm"
                  :for="`image${i}`"
                  title="Upload a certification or any evidence that proves your
                  membership"
                >
                  Proof of membership
                  <span class="req">*</span>
                </label>
                <input
                  type="file"
                  class="w-full rounded-lg border border-litgray py-1.5 px-3"
                  :id="`image${i}`"
                  accept="application/pdf"
                  :disabled="!prof.name || prof.uploading"
                  @change="(e) => uploadDoc(e, prof)"
                />
                <p class="flex justify-between">
                  <small class="text-idBlued">
                    <span v-if="!prof.image">
                      Accepted file type is <b>pdf</b>. Not more than 2mb
                    </span>
                    <a
                      v-else
                      :href="prof.image"
                      download=""
                      v-uppercase
                      class="underline text-xs"
                      >{{ prof.name }}.pdf</a
                    >
                  </small>

                  <small class="text-oneGreen font-semibold">
                    <spinner
                      v-if="prof.uploading"
                      color="gray-500"
                      class="w-4 h-4"
                      >Loading</spinner
                    >
                    <span v-if="prof.image">uploaded ✅</span>
                  </small>
                </p>
              </div>
            </div>

            <span
              v-if="i !== 0"
              @click="removeProf(i)"
              class="text-white bg-red-500 font-bold cursor-pointer px-2 py-0.5 border rounded mt-2 inline-block"
              >remove</span
            >
          </section>
        </div>

        <div class="flex justify-end mt-3">
          <button
            class="px-5 lg:px-9 py-2 rounded bg-oneGreen text-white"
            @click="addNewProf"
          >
            + Add another record
          </button>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script src="https://sdk.amazonaws.com/js/aws-sdk-2.813.0.min.js"></script>
<script>
import {
  fetchAcademicDegrees,
  fetchAcademicQualification,
} from "@/services/sourceData";
import { AcademicGrades } from "@/services/staffProfile";
const emptyProf = {
  name: null,
  image: null,
  uploading: false,
  year: null,
};

export default {
  name: "ProfessionalBodySurvey",

  props: {
    professionalBodies: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hasProfBody: true,
      academicDegrees: [],
      academicQualifications: [],
      academicGrades: AcademicGrades,
    };
  },

  watch: {
    hasProfBody(val) {
      this.handleHasProfBody(val);
    },
  },

  computed: {
    years() {
      const years = [];
      let y = new Date().getFullYear();
      const stp = y - 70;
      for (; y >= stp; y--) {
        years.push(y);
      }
      return years;
    },
  },

  methods: {
    async getDegree(e) {
      this.academicDegrees = await fetchAcademicDegrees(e.academyCode);
    },
    handleHasProfBody(val) {
      this.$emit("change-prof-body", val);
    },
    async getAcademicQualification(e) {
      this.academicQualifications = await fetchAcademicQualification(
        e.degreeCode
      );
    },
    addNewProf() {
      this.professionalBodies.answers.push({ ...emptyProf });
    },
    removeProf(i) {
      this.professionalBodies.answers.splice(i, 1);
    },
    slugify(text) {
      return text
        .toString()
        .trim()
        .toUpperCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w\-]+/g, "") // Remove all non-word characters
        .replace(/\-\-+/g, "-"); // Replace multiple - with single -
    },
    async uploadDoc(e, prof) {
      AWS.config.region = this.AWS_REGION;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.AWS_IDENTITY_POOL,
      });

      const fileSizeLimit = 2 * 1024 ** 2; // 2MB

      let file = e.target.files[0];
      if (file.size > fileSizeLimit) {
        this.$swal({
          icon: "error",
          text: "The selected file is more than 2mb",
        });
        file = null;
        return;
      }

      const verificationCode =
        this.$store.state?.staff?.profile?.verificationCode;
      const profName = this.slugify(prof.name);
      const key = `professional-bodies-survey/${verificationCode}_${profName}.pdf`;
      console.log(
        { key, bucket: this.AWS_BUCKET },
        process.env.VUE_APP_AWS_BUCKET
      );
      const params = {
        Bucket: this.AWS_BUCKET,
        Key: key,
        Body: file,
      };

      try {
        prof.uploading = true;
        const upload = new AWS.S3.ManagedUpload({ params });
        const resp = await upload.promise();
        console.log({ resp });
        prof.image = resp.Location;
        prof.uploading = false;
        delete prof.uploading;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.input[type="text"] {
  font-size: 1rem;
  line-height: 1rem;
}
</style>
